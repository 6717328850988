@include respond(tab-land) {
  .main.container {
    padding: 0 4rem;
  }
}

@include respond(phone) {
  .main.container {
    padding: 0 2rem;
  }
}
