.title {
  &__home {
    font-size: 4.2rem;
    text-transform: uppercase;
    color: $blue;
    position: relative;
  }

  &__home--underline {
    display: inline-block;
    span {
      position: relative;
      z-index: 10;
    }
    &::after {
      content: '';
      width: 100%;
      position: absolute;
      background-color: $yellow;
      bottom: 0rem;
      z-index: 1;
      left: 0;
      transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
      height: 45%;
    }
    &:hover::after {
      height: 100%;
    }
  }

  &__lien {
    font-size: 1.6rem;
    font-weight: 700;

    &::after {
    }

    &:hover {
      text-decoration: none;
    }
  }
}
