.mosaique {
	display: flex;
	flex-wrap: wrap;

	&__item {
		cursor: pointer;
		width: 25%;
		height: 30rem;
		flex-direction: column;
		@include flexCenter;
		@include matHover;

		@include respond(tab-land) {
			width: 33.3%;
		}
		@include respond(tab-port) {
			width: 50%;
		}

		@include respond(phone) {
			width: 50%;
			height: 22rem;
			&:nth-child(1) {
				order: 1;
			}

			&:nth-child(2) {
				order: 2;
			}

			&:nth-child(3) {
				order: 4;
			}

			&:nth-child(4) {
				order: 3;
			}

			&:nth-child(5) {
				order: 6;
			}

			&:nth-child(6) {
				order: 5;
			}

			&:nth-child(7) {
				order: 7;
			}
			&:nth-child(8) {
				order: 8;
			}
		}
	}

	span {
		font-size: 2.5rem;
		display: block;
		text-align: center;
		font-weight: 700;

		@include respond(phone) {
			font-size: 2rem;
		}

		strong {
			display: block;
			font-size: 7rem;
			@include respond(phone) {
				font-size: 4rem;
			}
		}
	}

	svg {
		width: 9rem;
		height: 9rem;
		margin-bottom: 1rem;

		@include respond(phone) {
			width: 6rem;
			height: 6rem;
		}
	}
}

.mosaique__image {
	position: relative;
	background-size: cover;
	background-position: center;

	.overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-color: $blue-trans;
		@include flexCenter;
	}
	span {
		color: $yellow;
	}
}

.mosaique__icon {
	&--dark {
		background-color: $dark-blue;

		span {
			color: $blue;
		}
	}

	&--blue {
		background-color: $blue;

		span {
			color: $dark-blue;
		}
	}
	&--white {
		span {
			color: $blue;
		}
	}

	&--yellow {
		background-color: $blue;

		span {
			color: $yellow;
		}
	}
}
