.photos-top {
	display: flex;
	justify-content: space-between;
	padding: 5rem 0 8rem;
	div {
		width: 38.5rem;
		height: 28rem;
		background-color: rgb(192, 192, 192);
		background-repeat: no-repeat;
		background-size: cover;
		@include flexCenter;
		@include matHover;

		&:hover {
			a {
				color: $yellow;
				text-decoration: underline;
			}
		}

		@include respond(tab-land) {
			width: 32%;
			// height: 18rem;
			background-position: center;
		}
		@include respond(tab-port) {
			width: 38.5rem;
			// height: 18rem;
			background-position: center;
		}
		@include respond(phone) {
			width: 100%;
			height: 18rem;
			background-position: center;
		}
	}

	a {
		font-size: 5rem;
		color: white;
		text-align: center;
		display: block;
		text-shadow: 4px 4px 13px rgba(0, 0, 0, 0.28);

		@include respond(phone) {
			font-size: 3rem;
			width: 100%;
		}
	}

	@include respond(tab-port) {
		flex-direction: column;
		align-items: center;

		div:nth-child(2) {
			margin: 2rem 0;
		}
	}

	@include respond(phone) {
		div:nth-child(2) {
			margin: 2rem 0;
		}
	}
}
