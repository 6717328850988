.acces-rapides {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 5rem;
	ul {
		display: flex;
		justify-content: space-between;
		padding: 5rem 0;
		width: 100%;
		@include respond(tab-land) {
			flex-wrap: wrap;
		}
	}

	li {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 25%;

		@include respond(tab-port) {
			width: 50%;
			margin-bottom: 4rem;
		}

		@include respond(phone) {
			width: 50%;
		}
	}

	.svg-circle {
		cursor: pointer;
		width: 22rem;
		height: 22rem;
		border-radius: 50%;
		border: solid 9px $yellow;
		transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
		margin-bottom: 2rem;
		&:hover {
			border: solid 20px $yellow;
			background-color: $light-yellow;
		}
		@include flexCenter;
		@include matHover;

		@include respond(phone) {
			width: 13rem;
			height: 13rem;
		}
	}

	svg {
		width: 12rem;
		height: 12rem;
		@include respond(phone) {
			width: 8rem;
			height: 8rem;
		}
	}

	a {
		color: $dark-blue;
		font-size: 2.5rem;
		text-transform: uppercase;
		text-align: center;
		font-weight: 700;

		@include respond(phone) {
			font-size: 2rem;
		}
	}
}
