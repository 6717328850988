.header__bandeau {
	position: relative;

	.button__yellow {
		position: absolute;
		top: 45%;
		transform: translateY(-50%);
		right: 2rem;
		@include respond(phone) {
			top: 10%;
		}
	}
}

.bandeau {
	overflow: hidden;
	position: relative;
	height: 41.5rem;

	&__masque {
		position: absolute;
		bottom: -3.3rem;
		width: 100%;
		@include respond(desktop) {
			height: 19rem;
			bottom: -5.2rem;
		}
		@include respond(tab-land) {
			bottom: -4.8rem;
		}
		@include respond(tab-port) {
			bottom: -6.3rem;
		}
		@include respond(phone) {
			// bottom: -7.5rem;
			display: none;
		}
	}

	&__item,
	&__int {
		width: 100%;
		height: 41.5rem;
		background-position: bottom;
		background-size: cover;
	}
}
