.annonces {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 7rem;

	h2 {
		margin-bottom: 3rem;

		@include respond(phone) {
			font-size: 3rem;
			margin-bottom: 0 !important;
		}
	}

	&__content {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		@include respond(tab-land) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__text {
		display: flex;
		justify-content: space-between;
		text-transform: uppercase;
		font-size: 2rem;
	}

	&__agence {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		background-color: $yellow;
		color: $blue;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 2rem;
		padding: 0.5rem 1rem;
		svg {
			width: 1rem;
			height: 2rem;
			margin-right: 1rem;
		}
	}

	&__list {
		display: grid;
		grid-template-areas: 'a b' 'a c' 'd d';
		grid-template-columns: 38rem 28rem;
		grid-template-rows: 18rem 18rem;
		grid-gap: 2rem;
		//width: 100%;
		//max-width: 68rem;

		@include respond(phone) {
			grid-template-columns: 20.5rem;
			grid-template-rows: 18rem;
		}

		@include respond(phone) {
			order: 2;
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}

	&__carto {
		position: relative;
		width: 100%;
		max-width: 48rem;
		height: 55rem;
		background-color: $light-blue;
		@include flexCenter;

		svg polygon:hover,
		svg path:hover {
			fill: $blue;
		}

		@include respond(phone) {
			margin-top: 4rem;
			width: 320px;

			svg {
				height: 50rem;
			}
		}

		@include respond(tab-port) {
			order: 1;
			margin-bottom: 5rem;
			height: 60rem;
		}

		@include respond(tab-land) {
			margin-top: 3rem;
		}
	}

	.button {
		grid-area: d;
		width: 20rem;
		justify-content: center;
		justify-self: end;
		margin-top: 4rem;

		@include respond(phone) {
			margin: 0 auto;
		}
	}
}

.annonces__item {
	&--une {
		grid-area: a;

		.annonces__image {
			width: 100%;
			height: 34rem;
			background-size: cover;
			position: relative;
			@include matHover;
			cursor: pointer;
		}
	}

	&--little {
		&.little1 {
			grid-area: b;
		}
		&.little2 {
			grid-area: c;
		}
		.annonces__image {
			width: 100%;
			height: 15rem;
			position: relative;
			background-size: cover;
			@include matHover;
			cursor: pointer;
		}

		// @include respond(phone) {
		//   display: none;
		// }
	}

	@include respond(phone) {
		margin-bottom: 3rem;
		&:last-of-type {
			display: none;
		}
	}

	&--empty {
		background-color: $light-blue;
	}
}

.carto {
	&__point {
		width: 4rem;
		height: 3rem;
		@include flexCenter;
		color: $blue;
		font-weight: 700;
		border-radius: 5px;
		font-size: 2.5rem;
		background-color: $yellow;
		position: absolute;

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			bottom: -10px;
			height: 0;
			border-style: solid;
			border-width: 10px 7.5px 0 7.5px;
			border-color: $yellow transparent transparent transparent;
		}
	}

	&__agence-de-bressuire-cerizay {
		top: 8rem;
		left: 15rem;
	}

	&__agence-de-thouars {
		left: 28rem;
		top: 10rem;

		@include respond(phone) {
			left: 20rem;
			top: 12rem;
		}
	}

	&__agende-de-niort {
		bottom: 12rem;
		@include respond(phone) {
			bottom: 15rem;
		}
	}

	&__agende-de-saint-maixent-lecole-parthenay {
		bottom: 28rem;
		@include respond(phone) {
			bottom: 30rem;
		}
	}
}
