.search-form {
  margin-right: 1.5rem;
  &__input {
    border: solid 2px $blue;
    background-color: $light-blue;
    font-family: $base;
    border-radius: 5px;
    padding: 0.7rem 1rem;
    width: 21.5rem;
    font-size: 1.5rem;
    @include placeholder {
      color: $blue;
    }

    @include respond(phone) {
      width: 19rem;
    }
  }
}
