/*!
Theme Name: Deux Sevres Habitat
Theme URI: vernalis.fr
Author: Vernalis
Author URI: http://underscores.me/
Description: Theme Vernalis
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: vernalis-theme
*/

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~@fortawesome/fontawesome-free/css/brands.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';

@import '/abstract/variables';
@import '/abstract/mixins';

@import '/base/fonts';
@import '/base/base';
@import '/base/headings';

@import '/components/search-form';
@import '/components/buttons';
@import '/components/bandeaux';
@import '/components/home/photos-top';
@import '/components/home/acces-rapides';
@import '/components/actualites';
@import '/components/home/annonces-home';
@import '/components/home/mosaique';

@import '/menus/header-menu';
// @import '/menus/un-clic-menu';
@import '/menus/pushbar';

// @import '/sliders/slider-actualites';

@import '/layout/header';
@import '/layout/front-page';
@import '/layout/footer';
