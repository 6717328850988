/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/dosis-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Dosis Regular'), local('Dosis-Regular'),
    url('../fonts/dosis-v7-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/dosis-v7-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/dosis-v7-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/dosis-v7-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/dosis-v7-latin-regular.svg#Dosis')
      format('svg'); /* Legacy iOS */
}
/* dosis-700 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/dosis-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Dosis Bold'), local('Dosis-Bold'),
    url('../fonts/dosis-v7-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/dosis-v7-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/dosis-v7-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/dosis-v7-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/dosis-v7-latin-700.svg#Dosis')
      format('svg'); /* Legacy iOS */
}

/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/work-sans-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Work Sans'), local('WorkSans-Regular'),
    url('../fonts/work-sans-v3-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/work-sans-v3-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/work-sans-v3-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/work-sans-v3-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/work-sans-v3-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
