.pushbar__icon {
	display: none;

	@include respond(tab-land) {
		margin: 1rem 1rem 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&--close,
	&--open {
		font-size: 2.5rem;
		color: $blue;
	}

	&--close {
		margin: 0.5rem 2rem;
	}
}

.title__icon {
	text-transform: uppercase;
	font-family: $text;
	font-size: 1.1rem;
	font-weight: 600;
	color: $blue;
}

.pushbar.opened {
	display: block;
}

html.pushbar_locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
}

.pushbar_locked .pushbar_main_content.pushbar_blur {
	filter: blur(15px);
}

.pushbar {
	z-index: 1000;
	position: fixed;
	will-change: transform;
	overflow-y: auto;
	transition: transform 0.5s ease;
	will-change: transform;
	background: $light-blue;
	border-left: solid 3px $yellow;
	display: none;

	@include respond(phone) {
		display: block;
	}
}

.pushbar_overlay {
	z-index: -999;
	position: fixed;
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100vh;
	top: 0;
	left: 0;
	will-change: opacity;
	transition: opacity 0.5s ease;
	opacity: 0;
	will-change: opacity;
	background: #3c3442;
}

html.pushbar_locked .pushbar_overlay {
	opacity: 0.8;
	z-index: 999;
	transition: opacity 0.5s ease;
}

.pushbar {
	&.from_left {
		top: 0;
		left: 0;
		width: 256px;
		max-width: 100%;
		height: 100%;
		min-height: 100vh;
		transform: translateZ(0) translateX(-100%);
	}
	&.from_right {
		top: 0;
		right: 0;
		width: 256px;
		max-width: 100%;
		height: 100%;
		min-height: 100vh;
		transform: translateZ(0) translateX(100%);
	}
	&.from_top {
		top: 0;
		left: 0;
		width: 100%;
		max-width: 100%;
		min-height: 150px;
		transform: translateZ(0) translateY(-100%);
	}
	&.from_bottom {
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 100%;
		min-height: 150px;
		transform: translateZ(0) translateY(100%);
	}
	&.opened {
		transform: translateX(0px) translateY(0px);
	}
}

.pushbar__menu {
	padding: 0 1rem;

	ul {
		padding: 0 1.5rem;
	}

	> li:first-child {
		display: none;
	}

	a {
		color: $dark-blue;
	}

	.sub-menu .active a {
		color: $blue;
		text-decoration: underline;
	}

	& > li > a {
		text-transform: uppercase;
		padding: 1rem 0;
		margin: 1rem 0;
		display: block;
		background-color: $blue;
		color: $yellow;
		font-weight: 700;
		text-align: center;
	}

	li {
		padding: 1rem 0;
	}
}
