.button {
	&__access {
		font-weight: 700;
		background-color: $blue;
		display: flex;
		align-items: center;
		border-radius: 5px;

		@include matHover;

		a {
			color: $yellow;
			line-height: 1;
			padding: 0 1rem;
		}

		span {
			width: 4.5rem;
			background-color: $light-blue;
			display: block;
			height: 100%;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			@include flexCenter;
		}

		svg {
			fill: $blue;
			width: 2rem;
			height: 2rem;
		}

		@include respond(phone) {
			position: absolute;
			height: 4rem;
			position: absolute;
			right: -3rem;
			top: 15rem;
			z-index: 99;
		}
	}

	&__yellow {
		text-transform: uppercase;
		background-color: $yellow;
		color: $blue;
		border-radius: 5px;
		font-size: 1.8rem;
		padding: 1.5rem 1.5rem;
		line-height: 1;
		@include matHover;
		font-weight: 700;

		&:hover {
			text-decoration: none;
			color: $yellow;
			background-color: $blue;
		}
	}

	&__blue {
		background-color: $blue;
		color: $yellow;
		border-radius: 5px;
		padding: 1rem 1.5rem;
		line-height: 1;
		@include matHover;
		font-weight: 700;

		&:hover {
			text-decoration: none;
		}
	}

	&__icon--span {
		display: flex;
		align-items: baseline;
		span {
			margin-left: 1rem;
		}

		.triangle {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 5px;
			border-color: transparent transparent transparent $yellow;
		}
	}

	&__icon--svg {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		a {
			color: $yellow;
			text-transform: uppercase;
			display: block;
			margin-right: 2rem;
		}
		svg {
			width: 2.5rem;
			height: 2.5rem;
		}
	}
}
