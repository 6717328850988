// Fonts
// -------------------------
$base: 'Dosis', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$text: 'Work Sans';

// Colors
// -------------------------

$black: #000;
$white: #fff;

$grey: #f3f3f3;
$dark-grey: #e4e4e4;
$text-grey: #a7a7a7;

$text-blue: #00adbb;

$blue: #00a5b8;
$light-blue: #cceff1;
$dark-blue: #0d1941;
$blue-trans: rgba(#00a5b8, 0.9);

$yellow: #f7ea49;
$light-yellow: #fdf9c7;

$color-placeholder: #eee;

$pdf: $dark-blue;
$doc: $blue;
$xls: $dark-blue;
$jpg: $light-blue;
