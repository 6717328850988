.header__menu {
  display: flex;
  margin-left: auto;
  width: 70rem;
  justify-content: space-between;
  & > li {
    position: relative;
    & > a {
      text-transform: uppercase;
      color: $dark-blue;
      font-weight: 700;
      padding: 1.5rem 0;
      display: block;
      text-align: center;
      position: relative;
    }

    &:not(:first-child) > a::after {
      content: '';
      width: 1px;
      height: 2.5rem;
      background-color: $blue;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      .sub-menu {
        display: block;
      }
    }

    &:not(:first-child) {
      width: 33%;
    }

    &:first-child {
      padding-right: 4rem;
    }
  }

  .sub-menu {
    z-index: 99;
    display: none;
    background-color: $blue-trans;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    min-width: 29rem;
    padding: 1rem 0;
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.2),
      0 11px 7px 0 rgba(0, 0, 0, 0.2);

    &::before {
      content: '';
      border: 1px solid #000;
      position: absolute;
      z-index: 99;
      display: block;
      top: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12.5px 10px 12.5px;
      border-color: transparent transparent #00afbb transparent;
    }
    a {
      color: $white;
      display: block;
      padding: 0.5rem 2rem;
    }

    a:hover {
      font-weight: 700;
      border-left: 5px solid $yellow;
      text-decoration: none;
    }
  }

  @include respond(tab-land) {
    display: none;
  }
}
