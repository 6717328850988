.header {
	border-top: solid 5px $yellow;

	&__top {
		.container {
			position: relative;
			display: flex;
			flex-direction: row-reverse;
			padding: 1.5rem 0;

			@include respond(tab-land) {
				padding: 1.5rem 2rem;
				justify-content: center;
			}
		}
		@include respond(tab-land) {
			display: flex;
			align-items: center;
		}
	}

	&__bottom {
		border-top: solid 2px $yellow;
		background-color: $light-blue;
		.container {
			position: relative;
		}
	}
	&__logo {
		position: absolute;
		left: 0;
		top: -6.7rem;
		z-index: 99;

		@include respond(tab-land) {
			width: 18rem;
		}
		@include respond(tab-port) {
			width: 18rem;
			top: -0.2rem;
		}
	}
}
