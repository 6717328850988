*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 62.5%; /* set to 10 for calculations*/
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	text-rendering: optimizeLegibility;

	@include respond(tab-port) {
		font-size: 55%;
	}
}

html,
body {
	height: 100%;
}

body {
	font-family: $base;
	box-sizing: border-box;
	font-size: 1.6rem;
}

.container {
	max-width: 120rem;
	width: 100%;
	margin: 0 auto;
	display: block;
}

a {
	color: #3897f0;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

ul,
li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

img {
	display: block;
	border: 0;
	max-width: 100%;
	height: auto;

	@include respond(phone) {
		margin: 0 auto;
	}
}

@include respond(phone) {
	figure {
		width: 100% !important;
	}
}

img:not([src]) {
	visibility: hidden;
}
/* Fixes Firefox anomaly during image load */
@-moz-document url-prefix() {
	img:-moz-loading {
		visibility: hidden;
	}
}

.alignleft {
	float: left;
	margin-right: 1.5em;
}

.alignright {
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
