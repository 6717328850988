.footer {
	background-color: $dark-blue;
	margin-top: 5rem;
	padding: 5rem 0;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		@include respond(tab-port) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__adresse {
		display: flex;
		justify-content: space-between;
		max-width: 47rem;
		width: 100%;

		h2 {
			text-transform: uppercase;
			color: $yellow;
			font-size: 2rem;
		}

		p {
			color: $white;
		}

		@include respond(phone) {
			flex-direction: column;
			align-items: center;

			img {
				margin-bottom: 3rem;
			}
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 24.5rem;
		height: 16rem;
		width: 100%;

		@include respond(tab-port) {
			padding: 4rem 0;
			height: 25rem;
		}
	}

	&__logos {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 18rem;
		height: 16rem;
		width: 100%;

		@include respond(phone) {
			max-width: 25rem;
			font-size: 1.7rem;

			p {
				margin-bottom: 2rem;
			}
		}

		p {
			color: $yellow;
		}

		.images {
			display: flex;
			justify-content: space-between;

			img {
				height: 6rem;
				align-self: flex-start;
			}
		}
	}

	&__bottom {
		padding: 1.5rem 0;
		background-color: $light-blue;
		ul,
		.container {
			justify-content: center;
			display: flex;

			@include respond(phone) {
				flex-direction: column;
				align-items: center;
			}
		}

		li:not(:last-child) {
			margin-right: 2rem;

			@include respond(phone) {
				margin-right: 0;
				margin-bottom: 1.5rem;
			}
		}

		a {
			color: $blue;
			font-family: $text;
		}
	}
}
