@include respond(tab-land) {
	.home .actualites__item:last-child {
		display: none;
	}
}
div.actualites {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	padding-bottom: 10rem;

	&__list {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding-top: 3.5rem;

		@include respond(phone) {
			flex-direction: column;
		}
	}

	&__item {
		width: 38rem;
		background-color: $light-blue;
		cursor: pointer;
		@include matHover;

		@include respond(tab-land) {
			width: 48%;
		}

		@include respond(phone) {
			width: 100%;
			&:first-child {
				margin-bottom: 4rem;
			}
		}
	}

	&__image {
		width: 100%;
		height: 19rem;
		background-size: cover;
	}

	&__text-container {
		color: $text-blue !important;
		font-family: $base;
		padding: 2.5rem 2rem;
	}

	&__title {
		text-transform: uppercase;
		font-size: 2.2rem;
		font-weight: 700;
	}

	.line {
		display: block;
		width: 4rem;
		height: 4px;
		background-color: $yellow;
		margin-top: 1.5rem;
	}

	p {
		margin-top: 1.5rem;
		color: $text-blue !important;
		font-family: $base !important;
	}

	a {
		margin-top: 1.5rem;
	}

	.button__blue {
		margin-top: 6rem;
	}
}

.actualites .main__int .container {
	flex-direction: column;
}

body.actualites {
	.actualites__list {
		flex-wrap: wrap;
		justify-content: initial;
	}

	.actualites__item {
		margin-bottom: 2rem;
	}

	@include respond(tab-land) {
		flex-direction: column;
		align-items: center;
		.actualites__item:nth-child(2n) {
			margin-left: 2rem;
		}
	}
	@include respond(desktop) {
		.actualites__item:nth-child(3n + 2) {
			margin-right: 2rem;
			margin-left: 2rem;
		}
	}
	@include respond(phone) {
		flex-direction: column;
		align-items: center;
		.actualites__item:nth-child(2n) {
			margin-left: 0;
		}
	}
}
