/*!
Theme Name: Deux Sevres Habitat
Theme URI: vernalis.fr
Author: Vernalis
Author URI: http://underscores.me/
Description: Theme Vernalis
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: vernalis-theme
*/
@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
@import url(~@fortawesome/fontawesome-free/css/fontawesome.css);
@import url(~@fortawesome/fontawesome-free/css/brands.css);
@import url(~@fortawesome/fontawesome-free/css/solid.css);
/* dosis-regular - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/dosis-v7-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Dosis Regular"), local("Dosis-Regular"), url("../fonts/dosis-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/dosis-v7-latin-regular.woff2") format("woff2"), url("../fonts/dosis-v7-latin-regular.woff") format("woff"), url("../fonts/dosis-v7-latin-regular.ttf") format("truetype"), url("../fonts/dosis-v7-latin-regular.svg#Dosis") format("svg");
  /* Legacy iOS */ }

/* dosis-700 - latin */
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/dosis-v7-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Dosis Bold"), local("Dosis-Bold"), url("../fonts/dosis-v7-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/dosis-v7-latin-700.woff2") format("woff2"), url("../fonts/dosis-v7-latin-700.woff") format("woff"), url("../fonts/dosis-v7-latin-700.ttf") format("truetype"), url("../fonts/dosis-v7-latin-700.svg#Dosis") format("svg");
  /* Legacy iOS */ }

/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/work-sans-v3-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Work Sans"), local("WorkSans-Regular"), url("../fonts/work-sans-v3-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/work-sans-v3-latin-regular.woff2") format("woff2"), url("../fonts/work-sans-v3-latin-regular.woff") format("woff"), url("../fonts/work-sans-v3-latin-regular.ttf") format("truetype"), url("../fonts/work-sans-v3-latin-regular.svg#WorkSans") format("svg");
  /* Legacy iOS */ }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  /* set to 10 for calculations*/
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility; }
  @media only screen and (max-width: 50em) {
    html {
      font-size: 55%; } }

html,
body {
  height: 100%; }

body {
  font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  box-sizing: border-box;
  font-size: 1.6rem; }

.container {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  display: block; }

a {
  color: #3897f0;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

img {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto; }
  @media only screen and (max-width: 37.5em) {
    img {
      margin: 0 auto; } }

@media only screen and (max-width: 37.5em) {
  figure {
    width: 100% !important; } }

img:not([src]) {
  visibility: hidden; }

/* Fixes Firefox anomaly during image load */
@-moz-document url-prefix() {
  img:-moz-loading {
    visibility: hidden; } }

.alignleft {
  float: left;
  margin-right: 1.5em; }

.alignright {
  float: right;
  margin-left: 1.5em; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.title__home {
  font-size: 4.2rem;
  text-transform: uppercase;
  color: #00a5b8;
  position: relative; }

.title__home--underline {
  display: inline-block; }
  .title__home--underline span {
    position: relative;
    z-index: 10; }
  .title__home--underline::after {
    content: '';
    width: 100%;
    position: absolute;
    background-color: #f7ea49;
    bottom: 0rem;
    z-index: 1;
    left: 0;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    height: 45%; }
  .title__home--underline:hover::after {
    height: 100%; }

.title__lien {
  font-size: 1.6rem;
  font-weight: 700; }
  .title__lien:hover {
    text-decoration: none; }

.search-form {
  margin-right: 1.5rem; }
  .search-form__input {
    border: solid 2px #00a5b8;
    background-color: #cceff1;
    font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 5px;
    padding: 0.7rem 1rem;
    width: 21.5rem;
    font-size: 1.5rem; }
    .search-form__input::-webkit-input-placeholder {
      color: #00a5b8; }
    .search-form__input:-moz-placeholder {
      color: #00a5b8; }
    .search-form__input::-moz-placeholder {
      color: #00a5b8; }
    .search-form__input:-ms-input-placeholder {
      color: #00a5b8; }
    @media only screen and (max-width: 37.5em) {
      .search-form__input {
        width: 19rem; } }

.button__access {
  font-weight: 700;
  background-color: #00a5b8;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
  .button__access:hover {
    transform: translate(0, -4px);
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .button__access a {
    color: #f7ea49;
    line-height: 1;
    padding: 0 1rem; }
  .button__access span {
    width: 4.5rem;
    background-color: #cceff1;
    display: block;
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .button__access svg {
    fill: #00a5b8;
    width: 2rem;
    height: 2rem; }
  @media only screen and (max-width: 37.5em) {
    .button__access {
      position: absolute;
      height: 4rem;
      position: absolute;
      right: -3rem;
      top: 15rem;
      z-index: 99; } }

.button__yellow {
  text-transform: uppercase;
  background-color: #f7ea49;
  color: #00a5b8;
  border-radius: 5px;
  font-size: 1.8rem;
  padding: 1.5rem 1.5rem;
  line-height: 1;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
  font-weight: 700; }
  .button__yellow:hover {
    transform: translate(0, -4px);
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .button__yellow:hover {
    text-decoration: none;
    color: #f7ea49;
    background-color: #00a5b8; }

.button__blue {
  background-color: #00a5b8;
  color: #f7ea49;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  line-height: 1;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
  font-weight: 700; }
  .button__blue:hover {
    transform: translate(0, -4px);
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
  .button__blue:hover {
    text-decoration: none; }

.button__icon--span {
  display: flex;
  align-items: baseline; }
  .button__icon--span span {
    margin-left: 1rem; }
  .button__icon--span .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #f7ea49; }

.button__icon--svg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .button__icon--svg a {
    color: #f7ea49;
    text-transform: uppercase;
    display: block;
    margin-right: 2rem; }
  .button__icon--svg svg {
    width: 2.5rem;
    height: 2.5rem; }

.header__bandeau {
  position: relative; }
  .header__bandeau .button__yellow {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    right: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .header__bandeau .button__yellow {
        top: 10%; } }

.bandeau {
  overflow: hidden;
  position: relative;
  height: 41.5rem; }
  .bandeau__masque {
    position: absolute;
    bottom: -3.3rem;
    width: 100%; }
    @media only screen and (min-width: 76em) {
      .bandeau__masque {
        height: 19rem;
        bottom: -5.2rem; } }
    @media only screen and (max-width: 75em) {
      .bandeau__masque {
        bottom: -4.8rem; } }
    @media only screen and (max-width: 50em) {
      .bandeau__masque {
        bottom: -6.3rem; } }
    @media only screen and (max-width: 37.5em) {
      .bandeau__masque {
        display: none; } }
  .bandeau__item, .bandeau__int {
    width: 100%;
    height: 41.5rem;
    background-position: bottom;
    background-size: cover; }

.photos-top {
  display: flex;
  justify-content: space-between;
  padding: 5rem 0 8rem; }
  .photos-top div {
    width: 38.5rem;
    height: 28rem;
    background-color: silver;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
    .photos-top div:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
    .photos-top div:hover a {
      color: #f7ea49;
      text-decoration: underline; }
    @media only screen and (max-width: 75em) {
      .photos-top div {
        width: 32%;
        background-position: center; } }
    @media only screen and (max-width: 50em) {
      .photos-top div {
        width: 38.5rem;
        background-position: center; } }
    @media only screen and (max-width: 37.5em) {
      .photos-top div {
        width: 100%;
        height: 18rem;
        background-position: center; } }
  .photos-top a {
    font-size: 5rem;
    color: white;
    text-align: center;
    display: block;
    text-shadow: 4px 4px 13px rgba(0, 0, 0, 0.28); }
    @media only screen and (max-width: 37.5em) {
      .photos-top a {
        font-size: 3rem;
        width: 100%; } }
  @media only screen and (max-width: 50em) {
    .photos-top {
      flex-direction: column;
      align-items: center; }
      .photos-top div:nth-child(2) {
        margin: 2rem 0; } }
  @media only screen and (max-width: 37.5em) {
    .photos-top div:nth-child(2) {
      margin: 2rem 0; } }

.acces-rapides {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem; }
  .acces-rapides ul {
    display: flex;
    justify-content: space-between;
    padding: 5rem 0;
    width: 100%; }
    @media only screen and (max-width: 75em) {
      .acces-rapides ul {
        flex-wrap: wrap; } }
  .acces-rapides li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25%; }
    @media only screen and (max-width: 50em) {
      .acces-rapides li {
        width: 50%;
        margin-bottom: 4rem; } }
    @media only screen and (max-width: 37.5em) {
      .acces-rapides li {
        width: 50%; } }
  .acces-rapides .svg-circle {
    cursor: pointer;
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    border: solid 9px #f7ea49;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
    .acces-rapides .svg-circle:hover {
      border: solid 20px #f7ea49;
      background-color: #fdf9c7; }
    .acces-rapides .svg-circle:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 37.5em) {
      .acces-rapides .svg-circle {
        width: 13rem;
        height: 13rem; } }
  .acces-rapides svg {
    width: 12rem;
    height: 12rem; }
    @media only screen and (max-width: 37.5em) {
      .acces-rapides svg {
        width: 8rem;
        height: 8rem; } }
  .acces-rapides a {
    color: #0d1941;
    font-size: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700; }
    @media only screen and (max-width: 37.5em) {
      .acces-rapides a {
        font-size: 2rem; } }

@media only screen and (max-width: 75em) {
  .home .actualites__item:last-child {
    display: none; } }

div.actualites {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10rem; }
  div.actualites__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 3.5rem; }
    @media only screen and (max-width: 37.5em) {
      div.actualites__list {
        flex-direction: column; } }
  div.actualites__item {
    width: 38rem;
    background-color: #cceff1;
    cursor: pointer;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
    div.actualites__item:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 75em) {
      div.actualites__item {
        width: 48%; } }
    @media only screen and (max-width: 37.5em) {
      div.actualites__item {
        width: 100%; }
        div.actualites__item:first-child {
          margin-bottom: 4rem; } }
  div.actualites__image {
    width: 100%;
    height: 19rem;
    background-size: cover; }
  div.actualites__text-container {
    color: #00adbb !important;
    font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding: 2.5rem 2rem; }
  div.actualites__title {
    text-transform: uppercase;
    font-size: 2.2rem;
    font-weight: 700; }
  div.actualites .line {
    display: block;
    width: 4rem;
    height: 4px;
    background-color: #f7ea49;
    margin-top: 1.5rem; }
  div.actualites p {
    margin-top: 1.5rem;
    color: #00adbb !important;
    font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }
  div.actualites a {
    margin-top: 1.5rem; }
  div.actualites .button__blue {
    margin-top: 6rem; }

.actualites .main__int .container {
  flex-direction: column; }

body.actualites .actualites__list {
  flex-wrap: wrap;
  justify-content: initial; }

body.actualites .actualites__item {
  margin-bottom: 2rem; }

@media only screen and (max-width: 75em) {
  body.actualites {
    flex-direction: column;
    align-items: center; }
    body.actualites .actualites__item:nth-child(2n) {
      margin-left: 2rem; } }

@media only screen and (min-width: 76em) {
  body.actualites .actualites__item:nth-child(3n + 2) {
    margin-right: 2rem;
    margin-left: 2rem; } }

@media only screen and (max-width: 37.5em) {
  body.actualites {
    flex-direction: column;
    align-items: center; }
    body.actualites .actualites__item:nth-child(2n) {
      margin-left: 0; } }

.annonces {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7rem; }
  .annonces h2 {
    margin-bottom: 3rem; }
    @media only screen and (max-width: 37.5em) {
      .annonces h2 {
        font-size: 3rem;
        margin-bottom: 0 !important; } }
  .annonces__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media only screen and (max-width: 75em) {
      .annonces__content {
        flex-direction: column;
        align-items: center; } }
  .annonces__text {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 2rem; }
  .annonces__agence {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f7ea49;
    color: #00a5b8;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    padding: 0.5rem 1rem; }
    .annonces__agence svg {
      width: 1rem;
      height: 2rem;
      margin-right: 1rem; }
  .annonces__list {
    display: grid;
    grid-template-areas: 'a b' 'a c' 'd d';
    grid-template-columns: 38rem 28rem;
    grid-template-rows: 18rem 18rem;
    grid-gap: 2rem; }
    @media only screen and (max-width: 37.5em) {
      .annonces__list {
        grid-template-columns: 20.5rem;
        grid-template-rows: 18rem; } }
    @media only screen and (max-width: 37.5em) {
      .annonces__list {
        order: 2;
        display: flex;
        flex-direction: column;
        width: 100%; } }
  .annonces__carto {
    position: relative;
    width: 100%;
    max-width: 48rem;
    height: 55rem;
    background-color: #cceff1;
    display: flex;
    justify-content: center;
    align-items: center; }
    .annonces__carto svg polygon:hover,
    .annonces__carto svg path:hover {
      fill: #00a5b8; }
    @media only screen and (max-width: 37.5em) {
      .annonces__carto {
        margin-top: 4rem;
        width: 320px; }
        .annonces__carto svg {
          height: 50rem; } }
    @media only screen and (max-width: 50em) {
      .annonces__carto {
        order: 1;
        margin-bottom: 5rem;
        height: 60rem; } }
    @media only screen and (max-width: 75em) {
      .annonces__carto {
        margin-top: 3rem; } }
  .annonces .button {
    grid-area: d;
    width: 20rem;
    justify-content: center;
    justify-self: end;
    margin-top: 4rem; }
    @media only screen and (max-width: 37.5em) {
      .annonces .button {
        margin: 0 auto; } }

.annonces__item--une {
  grid-area: a; }
  .annonces__item--une .annonces__image {
    width: 100%;
    height: 34rem;
    background-size: cover;
    position: relative;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
    cursor: pointer; }
    .annonces__item--une .annonces__image:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }

.annonces__item--little.little1 {
  grid-area: b; }

.annonces__item--little.little2 {
  grid-area: c; }

.annonces__item--little .annonces__image {
  width: 100%;
  height: 15rem;
  position: relative;
  background-size: cover;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
  cursor: pointer; }
  .annonces__item--little .annonces__image:hover {
    transform: translate(0, -4px);
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }

@media only screen and (max-width: 37.5em) {
  .annonces__item {
    margin-bottom: 3rem; }
    .annonces__item:last-of-type {
      display: none; } }

.annonces__item--empty {
  background-color: #cceff1; }

.carto__point {
  width: 4rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00a5b8;
  font-weight: 700;
  border-radius: 5px;
  font-size: 2.5rem;
  background-color: #f7ea49;
  position: absolute; }
  .carto__point::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    bottom: -10px;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: #f7ea49 transparent transparent transparent; }

.carto__agence-de-bressuire-cerizay {
  top: 8rem;
  left: 15rem; }

.carto__agence-de-thouars {
  left: 28rem;
  top: 10rem; }
  @media only screen and (max-width: 37.5em) {
    .carto__agence-de-thouars {
      left: 20rem;
      top: 12rem; } }

.carto__agende-de-niort {
  bottom: 12rem; }
  @media only screen and (max-width: 37.5em) {
    .carto__agende-de-niort {
      bottom: 15rem; } }

.carto__agende-de-saint-maixent-lecole-parthenay {
  bottom: 28rem; }
  @media only screen and (max-width: 37.5em) {
    .carto__agende-de-saint-maixent-lecole-parthenay {
      bottom: 30rem; } }

.mosaique {
  display: flex;
  flex-wrap: wrap; }
  .mosaique__item {
    cursor: pointer;
    width: 25%;
    height: 30rem;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08); }
    .mosaique__item:hover {
      transform: translate(0, -4px);
      box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1), 0 11px 7px 0 rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 75em) {
      .mosaique__item {
        width: 33.3%; } }
    @media only screen and (max-width: 50em) {
      .mosaique__item {
        width: 50%; } }
    @media only screen and (max-width: 37.5em) {
      .mosaique__item {
        width: 50%;
        height: 22rem; }
        .mosaique__item:nth-child(1) {
          order: 1; }
        .mosaique__item:nth-child(2) {
          order: 2; }
        .mosaique__item:nth-child(3) {
          order: 4; }
        .mosaique__item:nth-child(4) {
          order: 3; }
        .mosaique__item:nth-child(5) {
          order: 6; }
        .mosaique__item:nth-child(6) {
          order: 5; }
        .mosaique__item:nth-child(7) {
          order: 7; }
        .mosaique__item:nth-child(8) {
          order: 8; } }
  .mosaique span {
    font-size: 2.5rem;
    display: block;
    text-align: center;
    font-weight: 700; }
    @media only screen and (max-width: 37.5em) {
      .mosaique span {
        font-size: 2rem; } }
    .mosaique span strong {
      display: block;
      font-size: 7rem; }
      @media only screen and (max-width: 37.5em) {
        .mosaique span strong {
          font-size: 4rem; } }
  .mosaique svg {
    width: 9rem;
    height: 9rem;
    margin-bottom: 1rem; }
    @media only screen and (max-width: 37.5em) {
      .mosaique svg {
        width: 6rem;
        height: 6rem; } }

.mosaique__image {
  position: relative;
  background-size: cover;
  background-position: center; }
  .mosaique__image .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 165, 184, 0.9);
    display: flex;
    justify-content: center;
    align-items: center; }
  .mosaique__image span {
    color: #f7ea49; }

.mosaique__icon--dark {
  background-color: #0d1941; }
  .mosaique__icon--dark span {
    color: #00a5b8; }

.mosaique__icon--blue {
  background-color: #00a5b8; }
  .mosaique__icon--blue span {
    color: #0d1941; }

.mosaique__icon--white span {
  color: #00a5b8; }

.mosaique__icon--yellow {
  background-color: #00a5b8; }
  .mosaique__icon--yellow span {
    color: #f7ea49; }

.header__menu {
  display: flex;
  margin-left: auto;
  width: 70rem;
  justify-content: space-between; }
  .header__menu > li {
    position: relative; }
    .header__menu > li > a {
      text-transform: uppercase;
      color: #0d1941;
      font-weight: 700;
      padding: 1.5rem 0;
      display: block;
      text-align: center;
      position: relative; }
    .header__menu > li:not(:first-child) > a::after {
      content: '';
      width: 1px;
      height: 2.5rem;
      background-color: #00a5b8;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .header__menu > li:hover .sub-menu {
      display: block; }
    .header__menu > li:not(:first-child) {
      width: 33%; }
    .header__menu > li:first-child {
      padding-right: 4rem; }
  .header__menu .sub-menu {
    z-index: 99;
    display: none;
    background-color: rgba(0, 165, 184, 0.9);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    min-width: 29rem;
    padding: 1rem 0;
    box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.2), 0 11px 7px 0 rgba(0, 0, 0, 0.2); }
    .header__menu .sub-menu::before {
      content: '';
      border: 1px solid #000;
      position: absolute;
      z-index: 99;
      display: block;
      top: -1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12.5px 10px 12.5px;
      border-color: transparent transparent #00afbb transparent; }
    .header__menu .sub-menu a {
      color: #fff;
      display: block;
      padding: 0.5rem 2rem; }
    .header__menu .sub-menu a:hover {
      font-weight: 700;
      border-left: 5px solid #f7ea49;
      text-decoration: none; }
  @media only screen and (max-width: 75em) {
    .header__menu {
      display: none; } }

.pushbar__icon {
  display: none; }
  @media only screen and (max-width: 75em) {
    .pushbar__icon {
      margin: 1rem 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .pushbar__icon--close, .pushbar__icon--open {
    font-size: 2.5rem;
    color: #00a5b8; }
  .pushbar__icon--close {
    margin: 0.5rem 2rem; }

.title__icon {
  text-transform: uppercase;
  font-family: "Work Sans";
  font-size: 1.1rem;
  font-weight: 600;
  color: #00a5b8; }

.pushbar.opened {
  display: block; }

html.pushbar_locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none; }

.pushbar_locked .pushbar_main_content.pushbar_blur {
  filter: blur(15px); }

.pushbar {
  z-index: 1000;
  position: fixed;
  will-change: transform;
  overflow-y: auto;
  transition: transform 0.5s ease;
  will-change: transform;
  background: #cceff1;
  border-left: solid 3px #f7ea49;
  display: none; }
  @media only screen and (max-width: 37.5em) {
    .pushbar {
      display: block; } }

.pushbar_overlay {
  z-index: -999;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  will-change: opacity;
  transition: opacity 0.5s ease;
  opacity: 0;
  will-change: opacity;
  background: #3c3442; }

html.pushbar_locked .pushbar_overlay {
  opacity: 0.8;
  z-index: 999;
  transition: opacity 0.5s ease; }

.pushbar.from_left {
  top: 0;
  left: 0;
  width: 256px;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  transform: translateZ(0) translateX(-100%); }

.pushbar.from_right {
  top: 0;
  right: 0;
  width: 256px;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  transform: translateZ(0) translateX(100%); }

.pushbar.from_top {
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  min-height: 150px;
  transform: translateZ(0) translateY(-100%); }

.pushbar.from_bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  min-height: 150px;
  transform: translateZ(0) translateY(100%); }

.pushbar.opened {
  transform: translateX(0px) translateY(0px); }

.pushbar__menu {
  padding: 0 1rem; }
  .pushbar__menu ul {
    padding: 0 1.5rem; }
  .pushbar__menu > li:first-child {
    display: none; }
  .pushbar__menu a {
    color: #0d1941; }
  .pushbar__menu .sub-menu .active a {
    color: #00a5b8;
    text-decoration: underline; }
  .pushbar__menu > li > a {
    text-transform: uppercase;
    padding: 1rem 0;
    margin: 1rem 0;
    display: block;
    background-color: #00a5b8;
    color: #f7ea49;
    font-weight: 700;
    text-align: center; }
  .pushbar__menu li {
    padding: 1rem 0; }

.header {
  border-top: solid 5px #f7ea49; }
  .header__top .container {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    padding: 1.5rem 0; }
    @media only screen and (max-width: 75em) {
      .header__top .container {
        padding: 1.5rem 2rem;
        justify-content: center; } }
  @media only screen and (max-width: 75em) {
    .header__top {
      display: flex;
      align-items: center; } }
  .header__bottom {
    border-top: solid 2px #f7ea49;
    background-color: #cceff1; }
    .header__bottom .container {
      position: relative; }
  .header__logo {
    position: absolute;
    left: 0;
    top: -6.7rem;
    z-index: 99; }
    @media only screen and (max-width: 75em) {
      .header__logo {
        width: 18rem; } }
    @media only screen and (max-width: 50em) {
      .header__logo {
        width: 18rem;
        top: -0.2rem; } }

@media only screen and (max-width: 75em) {
  .main.container {
    padding: 0 4rem; } }

@media only screen and (max-width: 37.5em) {
  .main.container {
    padding: 0 2rem; } }

.footer {
  background-color: #0d1941;
  margin-top: 5rem;
  padding: 5rem 0; }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 50em) {
      .footer .container {
        flex-direction: column;
        align-items: center; } }
  .footer__adresse {
    display: flex;
    justify-content: space-between;
    max-width: 47rem;
    width: 100%; }
    .footer__adresse h2 {
      text-transform: uppercase;
      color: #f7ea49;
      font-size: 2rem; }
    .footer__adresse p {
      color: #fff; }
    @media only screen and (max-width: 37.5em) {
      .footer__adresse {
        flex-direction: column;
        align-items: center; }
        .footer__adresse img {
          margin-bottom: 3rem; } }
  .footer__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 24.5rem;
    height: 16rem;
    width: 100%; }
    @media only screen and (max-width: 50em) {
      .footer__buttons {
        padding: 4rem 0;
        height: 25rem; } }
  .footer__logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 18rem;
    height: 16rem;
    width: 100%; }
    @media only screen and (max-width: 37.5em) {
      .footer__logos {
        max-width: 25rem;
        font-size: 1.7rem; }
        .footer__logos p {
          margin-bottom: 2rem; } }
    .footer__logos p {
      color: #f7ea49; }
    .footer__logos .images {
      display: flex;
      justify-content: space-between; }
      .footer__logos .images img {
        height: 6rem;
        align-self: flex-start; }
  .footer__bottom {
    padding: 1.5rem 0;
    background-color: #cceff1; }
    .footer__bottom ul,
    .footer__bottom .container {
      justify-content: center;
      display: flex; }
      @media only screen and (max-width: 37.5em) {
        .footer__bottom ul,
        .footer__bottom .container {
          flex-direction: column;
          align-items: center; } }
    .footer__bottom li:not(:last-child) {
      margin-right: 2rem; }
      @media only screen and (max-width: 37.5em) {
        .footer__bottom li:not(:last-child) {
          margin-right: 0;
          margin-bottom: 1.5rem; } }
    .footer__bottom a {
      color: #00a5b8;
      font-family: "Work Sans"; }
