@mixin absCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

// MEDIA QUERY MANAGER

@mixin respond($breakpoint) {
	@if $breakpoint == phone {
		@media only screen and (max-width: 37.5em) {
			@content;
		} //600px
	}
	@if $breakpoint == phone-large {
		@media only screen and (max-width: 46.8em) {
			@content;
		} //600px
	}
	@if $breakpoint == tab-port {
		@media only screen and (max-width: 50em) {
			@content;
		} //900px
	}
	@if $breakpoint == tab-land {
		@media only screen and (max-width: 75em) {
			@content;
		} //1200px
	}
	@if $breakpoint == desktop {
		@media only screen and (min-width: 76em) {
			@content;
		} //1200px
	}
	@if $breakpoint == big-desktop {
		@media only screen and (min-width: 112.5em) {
			@content;
		} //1800
	}
}

@mixin matHover() {
	transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
	box-shadow: 0 1px 1px 1px rgba(10, 16, 34, 0.08);
	&:hover {
		transform: translate(0, -4px);
		box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.1),
			0 11px 7px 0 rgba(0, 0, 0, 0.1);
	}
}

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@mixin placeholder {
	@include optional-at-root("::-webkit-input-placeholder") {
		@content;
	}

	@include optional-at-root(":-moz-placeholder") {
		@content;
	}

	@include optional-at-root("::-moz-placeholder") {
		@content;
	}

	@include optional-at-root(":-ms-input-placeholder") {
		@content;
	}
}
